@import "helpers/mq";

/* @import must be at top of file, otherwise CSS will not work */
/*@import url("//hello.myfonts.net/count/37b521");
*/

@font-face {
    font-family: 'Calibre';
    src: url('assets/fonts/Calibre-Light.eot');
    src: url('assets/fonts/Calibre-Light.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Calibre-Light.woff2') format('woff2'),
        url('assets/fonts/Calibre-Light.woff') format('woff'),
        url('assets/fonts/Calibre-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url('assets/fonts/Calibre-Medium.eot');
    src: url('assets/fonts/Calibre-Medium.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Calibre-Medium.woff2') format('woff2'),
        url('assets/fonts/Calibre-Medium.woff') format('woff'),
        url('assets/fonts/Calibre-Medium.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sectra';
    src: url('assets/fonts/GTSectra-BookItalic.eot');
    src: url('assets/fonts/GTSectra-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/GTSectra-BookItalic.woff2') format('woff2'),
        url('assets/fonts/GTSectra-BookItalic.woff') format('woff'),
        url('assets/fonts/GTSectra-BookItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}



/* Define commonly used colors and fonts as variables */
:root {
  /* Colors */
  --black: #000000;
  --white: #ffffff;
  --grey: rgb(193,193,193);
  /* Fonts */
  --f1: normal 1.8rem/1.4 "Calibre", sans-serif;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
}

body {
  font: sans-serif; // for ie
  font: var(--f1);
  letter-spacing: 0.115em;
  color: white; // for ie
  color: var(--white);
  background: black;
  background: var(--black);
}

a {
  color: inherit;
  text-decoration: none;
}

sup {
  top: -0.27em;
}

/* Define Commonly Used classes here if any — although majority of css should be in the components */
:global .mxa {
  margin-left: auto;
  margin-right: auto;
}